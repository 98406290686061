<template>
  <ui-img
    src="/img/circular-rocket.svg"
    :alt="$t('components.bootstrap.spinner.loading')"
  />

  <NuxtPage />
</template>

<style lang="scss" scoped>
body {
  background-color: #f1f5f9;
}

img {
  width: 150px;
  height: 150px;
  animation: rotation 2s infinite linear;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
